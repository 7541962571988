
import SsrCarousel from 'vue-ssr-carousel'
// eslint-disable-next-line no-unused-vars
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import reviewData from '@/data/reviews.json'
export default {
  components: { SsrCarousel },
  props: {
    lines: {
      type: String,
      default: '2',
    },
    display_reviews: {
      type: String,
      default: 'false',
    },
    logo: {
      type: String,
      default: 'false',
    },
    alignlogo: {
      type: String,
      default: 'false',
    },
  },
  data: () => ({
    isVisible:false,
    reviews: reviewData,
    settings: {
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 1040,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
      ]
    }
  }),
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible
      // console.log(isVisible, entry)
    }
  },

  /*
  async fetch() {
    try {
      this.reviews = await fetch('https://api.reviews.co.uk/merchant/reviews?store=tefl-org-uk&minRating=4').then(res => res.json())
    } catch (err) {
      console.log(err)
    }
  }
  */
}
